smallsmallsmallsmallsmall
<template>
  <b-overlay :show="show" rounded="sm" no-fade>
    <b-card-code title="Perubahan Data SIP">
      <b-row>
        <b-col cols="9">
          <p>
            Untuk melakukan perubahan data SIP. Silahkan untuk melakukan
            pembuatan SIP baru di menu pembuatan SIP
          </p>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="9" align="end">
          <b-button variant="primary" type="submit" @click="kePembuatanSIP()">
            Buat SIP Baru
          </b-button>
        </b-col>
      </b-row>
    </b-card-code>
  </b-overlay>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BFormTextarea,
  BFormSelect,
  BFormDatepicker,
  BFormCheckbox,
  BOverlay,
} from "bootstrap-vue";
import { required, email } from "@validations";
import axios from "@axios";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormTextarea,
    BFormSelect,
    BFormDatepicker,
    BFormCheckbox,
    BOverlay,
    flatPickr,
  },
  data() {
    return {};
  },

  beforeMount() {},

  methods: {
    kePembuatanSIP() {
      this.$router.push("/pembuatan-sip");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
